import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import QuoteSummary from '../state/model/summary.model';
import { VaultService } from '../shared/services/vault.service';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  policyState!: string;
  mco!: string;
  pco!: string;
  orig!: string;
  private credentials: any;
  constructor(private store: Store<{ quoteSummary: QuoteSummary }>, private vaultService: VaultService) {
    this.fetchCredentials();
    this.store.select('quoteSummary').subscribe(data => {
      this.vaultService.credentials$
      this.policyState = data.policyState;
      this.mco = data.mco;
      this.orig = data.orig;
    });
  }
  private fetchCredentials(): void {
    this.vaultService.readVaultData().subscribe(
        credentials => {
            this.vaultService.credentialsSubject.next(credentials);
        },
        error => {
            console.error('Error fetching credentials:', error);
            // Handle error appropriately (e.g., show error message to user)
        }
    );
}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.vaultService.credentials$.pipe(
        filter(credentials => !!credentials), // Ensure credentials are available
        take(1), // Take credentials only once
      switchMap(credentials => {
    // TODO: Temp fix for Payments URL
    const isPaymentURL: boolean = req.url.indexOf('pymtms/') !== -1;
    const isAutoPayRequest: boolean = req.url.indexOf('recurringSetup') !== -1;
    // console.log('IP Address :::: ', req.headers.get('x-forwarded-for'));

    const modifiedReq = req.clone({
      headers: req.headers.append('frms_ipaddress', '10.140.229.154')
                          .append('frms_state', this.policyState)
                          .append('frms_brand', isAutoPayRequest ? this.mco.concat('00') : 'BW')
                          .append('frms_lob', 'APV')
                          .append('frms_source', 'BWR')
                          .append('frms_region', isPaymentURL ? `${environment.paymentRegion}` : 'Local')
                          .append('frms_tid', '9999999999')
                          .append('frms_appid', 'Select4.0')
                          .append('authorizationtype', this.orig === 'FIG' ? 'EMP_OKTA' : 'BWIA_OKTA')
                          .append('frmsregion', `${environment.farmersRegion}`) 
                          .append('usertype', this.orig === 'FIG' ? 'BWEA': 'BWIA')
                          .append('client_id', credentials.data.data.muleClientId)
                          .append('client_secret',credentials.data.data.muleClientSecret)
                          .append('Content-Type', 'application/json')
    });
    return next.handle(modifiedReq);
  })
);
}
}
