import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VaultService {

  public credentialsSubject = new BehaviorSubject<any>(null);
  credentials$ = this.credentialsSubject.asObservable();

  userAgent = '';
  public static log: VaultService;
  private _http: HttpClient;
  kvPairToken = '';
  
  constructor(handler: HttpBackend) {
    VaultService.log = this;
    this._http = new HttpClient(handler);  // Logic to bypass the intecerptors for the spefic services
  }


  readVaultData(): Observable<any> {
    return this._http.post<any>(`${environment.loggerUrl}vault`, {}).pipe(
      tap(credentials => {
        this.credentialsSubject.next(credentials);
      }),
      catchError(error => {
        console.error('Error fetching credentials:', error);
        throw error;
      })
    );
  }

  clearCredentials(): void {
    this.credentialsSubject.next(null);
  }

}

