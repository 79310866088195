import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import QuoteSummary from 'src/app/state/model/summary.model';
import { SsoService } from 'src/app/services/sso.service';
import { Tracker } from 'src/app/shared/utilities/tracker';
import { TokenGenRes } from 'src/app/shared/model/sso/generate-token-res.model';
import { SpinnerStatusService } from 'src/app/shared/services/spinner-status.service';
import { environment } from 'src/environments/environment';
import { ObjectUtils } from 'src/app/shared/utilities/object-utils';
import { GlobalConstants } from 'src/app/constants/global.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  channel!: string;
  producerCode!: string;
  // primaryProducerCode!: string;
  accessToken!: any;
  orig!: any;
  quoteNumber!: string;
  policyStateId!: string;
  userId!: string;
  qid: string = '';
  chatEAFeatureFlag= environment.chatEAFeatureFlag;

  constructor(public ssoDataService: SsoService,
    private store: Store<{ quoteSummary: QuoteSummary }>,
    private showSpinnerService: SpinnerStatusService, private logTracker: Tracker) {
  }

  ngOnInit(): void {
    this.store.select('quoteSummary').subscribe(data => {
      this.channel = data.channel;
      this.producerCode = data.producerCode;
      this.accessToken = data.accessToken;
      this.orig = data.orig;
      this.quoteNumber = data.qid;
      this.policyStateId = data.policyState;
      this.userId = data.producerUserId;
    });
  }

  launchProgramGuide() {
    let programGuideURL = this.channel === 'EA' ? `${environment.programGuideEA}` : `${environment.programGuideIA}`;
    programGuideURL = programGuideURL.replace('{StateID}', this.policyStateId);
    this.logTracker.loginfo(this.constructor.name, 'launchProgramGuide', 'Launching Program Guide', 'Forms Path='.concat(programGuideURL));
    window.open(programGuideURL, "_blank");
  }


  redirectForms() {

    this.showSpinnerService.showSpinner(true);
    if (!ObjectUtils.isFieldEmpty(this.accessToken)) {
      let bwFormURL = this.channel === 'EA' ? `${environment.bwpFormsEAAccesToken}` : (this.orig !== '' && this.userId !== '' ) ? `${environment.bwpFormsEAAccesToken}` : `${environment.bwpFormsIAAccesToken}`;
      bwFormURL = bwFormURL.replace('{PID}', this.producerCode);
      bwFormURL = bwFormURL.replace('{SID}', '');
      bwFormURL = bwFormURL.replace('{AccessToken}', encodeURIComponent(this.accessToken));
      bwFormURL = bwFormURL.replace('{orig}', this.orig);
      bwFormURL = bwFormURL.replace('{username}', this.userId);

      console.log('Forms URL : ', bwFormURL);

      this.logTracker.loginfo('HeaderComponent', 'redirectForms', 'Redirect to Form Using Access Token', 'Forms Path='.concat(bwFormURL));

      this.showSpinnerService.showSpinner(false);

      window.open(bwFormURL, "_blank");
    }
  }

  launchHelpChatForEA()
  {
    let helpChatEAURL =  `${environment.helpChatEAURL}`;
    helpChatEAURL = helpChatEAURL.replace('{brand}', GlobalConstants.BRISTOL_WEST);
    helpChatEAURL = helpChatEAURL.replace('{lob}', GlobalConstants.LOB);
    helpChatEAURL = helpChatEAURL.replace('{stateID}', this.policyStateId);
    helpChatEAURL = helpChatEAURL.replace('{src}', GlobalConstants.SOURCE_SYSTEM);
    helpChatEAURL = helpChatEAURL.replace('{producercode}', this.producerCode);
    this.logTracker.loginfo(this.constructor.name, 'launchHelpChatForEA', 'Launching Help/Chat for EA for QuoteNumber='.concat(this.quoteNumber + '|policyState='.concat(this.policyStateId)) , 'HelpChatForEA Path='.concat(helpChatEAURL));
    window.open(helpChatEAURL, "_blank");
  }
}
