import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-application-popup',
  templateUrl: './application-popup.component.html',
  styleUrls: ['./application-popup.component.scss']
})
export class ApplicationPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ApplicationPopupComponent>,) {
    
  }

  ngOnInit(): void {
  }

  onClickOk(val: boolean): void {
    this.dialogRef.close('ok');
  }

}
