import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenGenRes } from '../shared/model/sso/generate-token-res.model';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(private httpClient: HttpClient) { }

  introSpectAPI(accesstokenReq: any): Observable<object> {
    const bwintrospectAPIUrl = `${environment.bwintrospectAPI}`;
    return this.httpClient.post(bwintrospectAPIUrl,accesstokenReq);
  }

  getAgentLogin(agentReq:any): Observable<object> {
    const bwagentSecurityAPIUrl = `${environment.baseUrl}${environment.bwgetAgentLoginAPI}`;
    return this.httpClient.post(bwagentSecurityAPIUrl,agentReq);
  }

}
