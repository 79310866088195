import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import QuoteSummary from 'src/app/state/model/summary.model';

@Component({
  selector: 'app-delete-driver-dialog',
  templateUrl: './delete-driver-dialog.component.html',
  styleUrls: ['./delete-driver-dialog.component.scss']
})
export class DeleteDriverDialogComponent implements OnInit {

  riskState: any;
  driverHouseHoldStates = ['MI'];
  driverHousHoldFlag: boolean = false;

  constructor(public dialogRef: MatDialogRef<DeleteDriverDialogComponent>,  @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ,public store: Store<{ quoteSummary: QuoteSummary }>,) {
    this.store.select('quoteSummary').subscribe(data => {
      this.riskState = data.policyState;
    });
   }

  ngOnInit(): void {
    this.driverHousHoldFlag = this.driverHouseHoldStates.includes(this.riskState);
  }

}
