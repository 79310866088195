import { ErrorHandler, Injectable } from "@angular/core";
import { Tracker } from "../shared/utilities/tracker";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler{
  count:number = 1;
  constructor(private logTracker: Tracker) {}
  handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    this.logTracker.logerror('GlobalErrorHandler', 'handleError', 'ChunkLoadError', 'ErrorCount = ', this.count);
    if(chunkFailedMessage.test(error.message)) {
      console.log("Error ChunkLoadError count ==== ", this.count)
      window.location.reload();
      this.logTracker.logerror('GlobalErrorHandler', 'handleError', 'ChunkLoadError',
        'Error=', error.message);
      this.count++;
    }
  }
}
