<h4 class="header" mat-dialog-title>Important Notice!</h4>
<div mat-dialog-content>
    <mat-card class="card-content">
    <p>Michigan Senate Bill 1 requires that the insured sign
        and return coverage selection forms at the time of new
        business and at each renewal. To avoid cancellation or
        coverage changes which may result in an increase in
        premium please make sure that customer is aware signed
        policy documents must be provided within 5 days of this
        policy being submitted.</p>

        <p style="margin-top: 20px;">The fastest and easiest way for the insured to sign forms
            is to use our eSignature program or by creating an
            account on bristolwest.com.</p>
        <p style="margin-top: 20px;">If the insured has not enrolled in eSignature forms can
            be returned:</p>

        <ul style="margin-left: -28px;">
            <li>
                By email to
                policyservicedocuments@farmersinsurance.com
            </li>
            <li>
                By fax to 1-888-888-0070
            </li>
            <li>
                By mail to Bristol West Insurance PO Box 31029,
                Independence, OH 44131-0029
            </li>
            <li>
                Using the Submit a Document link in the Bristol West
                Producer Portal or the customer website at bristolwest.com
            </li>
            </ul>

            <p style="margin-top: 20px;">Please allow 3 business days for processing time once
                forms have been received.</p>

            </mat-card>
</div>
<div class="footer-buttons pt-2">
    <button mat-button mat-raised-button [mat-dialog-close]="true" (click) = "onClickOk(true)">OK</button>
</div>